import React from "react";
import "./scss/diamondvaults.scss";
import "./scss/app.scss";

function VaultComponent() {
  const vaultPath = { vaultPath: "duelwhales" };
  return <div id="diamond-vaults" {...vaultPath}></div>;
}

function App() {
  return (
    <>

      <VaultComponent />
    </>
  );
}

export default App;
